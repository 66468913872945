import type { InferGetStaticPropsType } from "next";
import Head from "next/head";
import { Home } from "views";
import { getSiteMetadata } from "lib/notion";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export const getStaticProps = async ({ locale }: { locale: string }) => {
  const translation = await serverSideTranslations(locale || "ko", ["common"]);
  const { props: metaData } = await getSiteMetadata();
  return {
    props: {
      ...translation,
      ...metaData,
    },
  };
};

const Main = (props: InferGetStaticPropsType<typeof getStaticProps>) => {
  // TODO: @sangyouh 여기에 `locale` Prop은 사용하지 않음. getStaticProps에 locale이 들어가지 않아도 되지 않을지?
  const { title } = props;
  return (
    <div>
      <Head>
        <title>{title}</title>
      </Head>
      <Home />
    </div>
  );
};

export default Main;
